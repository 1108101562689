// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="checkbox"] {
  transform: scale(1.5);
  margin: 10px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  & > * {
    cursor: pointer;
  }
}

.headerContainer {
  text-align: center;
  margin-bottom: 0px; /* Adjust this value to add space below the header */
}`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/StepFive/StepFive.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR;IACE,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;EAClB,kBAAkB,EAAE,oDAAoD;AAC1E","sourcesContent":["input[type=\"checkbox\"] {\n  transform: scale(1.5);\n  margin: 10px;\n}\n.checkboxContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n  & > * {\n    cursor: pointer;\n  }\n}\n\n.headerContainer {\n  text-align: center;\n  margin-bottom: 0px; /* Adjust this value to add space below the header */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
