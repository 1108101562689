// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formGrid {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.formGrid > div {
  display: flex;
  gap: 10px;
}

.formGrid > div > * {
  width: 100%;
}

.first-row > *:nth-child(1) {
  flex: 1 1;
}

.first-row > *:nth-child(2) {
  flex: 2 1;
}

.second-row > *:nth-child(1) {
  flex: 2 1;
}

.second-row > *:nth-child(2),
.second-row > *:nth-child(3) {
  flex: 1 1;
}

.radio-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.radio-buttons > label {
  width: auto;
}

@media (max-width: 768px) {
  .formGrid {
    flex-direction: column;
  }

  .formGrid > div {
    flex-direction: column;
  }

  .radio-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

.headerContainer {
  text-align: center;
  margin-bottom: 0px;
}

`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/StepTwo/StepTwo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,SAAO;AACT;;AAEA;;EAEE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;AACF;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".formGrid {\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n}\n\n.formGrid > div {\n  display: flex;\n  gap: 10px;\n}\n\n.formGrid > div > * {\n  width: 100%;\n}\n\n.first-row > *:nth-child(1) {\n  flex: 1;\n}\n\n.first-row > *:nth-child(2) {\n  flex: 2;\n}\n\n.second-row > *:nth-child(1) {\n  flex: 2;\n}\n\n.second-row > *:nth-child(2),\n.second-row > *:nth-child(3) {\n  flex: 1;\n}\n\n.radio-buttons {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.radio-buttons > label {\n  width: auto;\n}\n\n@media (max-width: 768px) {\n  .formGrid {\n    flex-direction: column;\n  }\n\n  .formGrid > div {\n    flex-direction: column;\n  }\n\n  .radio-buttons {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n\n.headerContainer {\n  text-align: center;\n  margin-bottom: 0px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
