// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jiro-form {
  width: 90vw;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: red;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
}

.zenvi-icon {
  width: 8rem;
  height: 6rem;
  max-height: 20vh;
  object-fit: contain;
}

.steps-indicator {
  text-align: end;
}

@media (max-width: 768px) {
  .jiro-form {
    padding: 1rem;
  }
  .steps-indicator {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".form-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.jiro-form {\n  width: 90vw;\n  max-width: 700px;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  background-color: white;\n  padding: 2rem;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.error-message {\n  color: red;\n  text-align: center;\n}\n\n.button-container {\n  display: flex;\n  justify-content: center;\n}\n\n.zenvi-icon {\n  width: 8rem;\n  height: 6rem;\n  max-height: 20vh;\n  object-fit: contain;\n}\n\n.steps-indicator {\n  text-align: end;\n}\n\n@media (max-width: 768px) {\n  .jiro-form {\n    padding: 1rem;\n  }\n  .steps-indicator {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
