// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#facturas {
  display: none;
}

.custom-button-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  button.custom-button {
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    font-size: 20px;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .custom-button-label {
    padding-left: 8px;
    cursor: pointer;
  }
}

.headerContainer {
  text-align: center;
  margin-bottom: 0px; /* Adjust this value to add space below the header */
}
`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/StepThree/StepThree.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;EAClB,kBAAkB,EAAE,oDAAoD;AAC1E","sourcesContent":["#facturas {\n  display: none;\n}\n\n.custom-button-wrapper {\n  width: fit-content;\n  button.custom-button {\n    width: fit-content;\n    align-self: center;\n    font-size: 20px;\n    padding: 0;\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n  }\n\n  .custom-button-label {\n    padding-left: 8px;\n    cursor: pointer;\n  }\n}\n\n.headerContainer {\n  text-align: center;\n  margin-bottom: 0px; /* Adjust this value to add space below the header */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
