// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global css variables */
:root {
  --bg-color: #e6ebeb;
  --orange-color: #ff5722;
  --orange-light-color: #ff8a50;
  --disabled-bg-color: #e0e0e0;
  --disabled-text-color: #a0a0a0;
}

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Plus Jakarta Sans", "Roboto", "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--orange-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 300;
  line-height: 2rem;
}

.custom-button {
  box-sizing: border-box;
  background-color: var(--orange-color);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 0.5em 1.5em;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: var(--orange-light-color);
}

.custom-button:disabled {
  background-color: var(--disabled-bg-color);
  color: var(--disabled-text-color);
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,SAAS;EACT,gEAAgE;EAChE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;AACX;;AAEA;;;;;;EAME,SAAS;EACT,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,qCAAqC;EACrC,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,0CAA0C;EAC1C,iCAAiC;EACjC,mBAAmB;AACrB","sourcesContent":["/* global css variables */\n:root {\n  --bg-color: #e6ebeb;\n  --orange-color: #ff5722;\n  --orange-light-color: #ff8a50;\n  --disabled-bg-color: #e0e0e0;\n  --disabled-text-color: #a0a0a0;\n}\n\nbody {\n  background-color: var(--bg-color);\n  margin: 0;\n  font-family: \"Plus Jakarta Sans\", \"Roboto\", \"Oxygen\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n* {\n  box-sizing: border-box;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\na {\n  color: var(--orange-color);\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\np {\n  margin: 0;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n  font-weight: 300;\n  line-height: 2rem;\n}\n\n.custom-button {\n  box-sizing: border-box;\n  background-color: var(--orange-color);\n  color: white;\n  border: none;\n  border-radius: 50px;\n  padding: 0.5em 1.5em;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.custom-button:hover {\n  background-color: var(--orange-light-color);\n}\n\n.custom-button:disabled {\n  background-color: var(--disabled-bg-color);\n  color: var(--disabled-text-color);\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
