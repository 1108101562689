// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  box-sizing: border-box;
  padding: 1.5em 0.75em 0.25em;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
  position: relative;
}

.form-control:focus {
  outline: none;
  border-color: #000;
}

.form-group {
  position: relative;
}

.form-control-placeholder {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  background: transparent;
  padding: 0 0.25em;
  font-size: 16px;
  color: #666;
  transition: all 0.2s;
  pointer-events: none;
}

.form-control:focus + .form-control-placeholder,
.form-control:not(:placeholder-shown) + .form-control-placeholder,
.form-group.focused .form-control-placeholder {
  top: 0.25em;
  left: 0.75em;
  font-size: 12px;
  color: #000;
}

.form-control.is-invalid {
  border-color: #e02020;
}

.form-group.error .form-control-placeholder {
  color: #e02020;
}

span.error-msg {
  color: #e02020;
  font-size: 12px;
}

.form-group.disabled .form-control {
  background: #f2f2f2;
  border-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.form-group.disabled .form-control-placeholder {
  color: #a0a0a0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,4BAA4B;EAC5B,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;;;EAGE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".form-control {\n  box-sizing: border-box;\n  padding: 1.5em 0.75em 0.25em;\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n  transition: border-color 0.2s;\n  position: relative;\n}\n\n.form-control:focus {\n  outline: none;\n  border-color: #000;\n}\n\n.form-group {\n  position: relative;\n}\n\n.form-control-placeholder {\n  position: absolute;\n  top: 0.75em;\n  left: 0.75em;\n  background: transparent;\n  padding: 0 0.25em;\n  font-size: 16px;\n  color: #666;\n  transition: all 0.2s;\n  pointer-events: none;\n}\n\n.form-control:focus + .form-control-placeholder,\n.form-control:not(:placeholder-shown) + .form-control-placeholder,\n.form-group.focused .form-control-placeholder {\n  top: 0.25em;\n  left: 0.75em;\n  font-size: 12px;\n  color: #000;\n}\n\n.form-control.is-invalid {\n  border-color: #e02020;\n}\n\n.form-group.error .form-control-placeholder {\n  color: #e02020;\n}\n\nspan.error-msg {\n  color: #e02020;\n  font-size: 12px;\n}\n\n.form-group.disabled .form-control {\n  background: #f2f2f2;\n  border-color: #e0e0e0;\n  color: #a0a0a0;\n  cursor: not-allowed;\n}\n\n.form-group.disabled .form-control-placeholder {\n  color: #a0a0a0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
