// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultCard-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.resultCard {
  padding: 1.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.resultCard-content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.resultCard-content h2 {
  font-weight: 700;
}

.resultCard-content span {
  font-weight: 300;
}

.resultCard-content > span {
  font-size: 14px;
}

.resultCard-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.zenvi-arrow-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zenvi-arrow-icon {
  width: 8rem;
  height: 5rem;
  max-height: 20vh;
  object-fit: contain;
}

.provider-text {
  margin-top: 8px; /* Adjust the spacing as needed */
  font-size: 12px;
  color: #333;
}

@media (max-width: 768px) {
  .zenvi-arrow-icon {
    height: 3rem;
  }
}

.headerContainer {
  text-align: center;
  margin-bottom: 0px; /* Adjust this value to add space below the header */
}`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/StepFour/StepFour.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe,EAAE,iCAAiC;EAClD,eAAe;EACf,WAAW;AACb;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,kBAAkB;EAClB,kBAAkB,EAAE,oDAAoD;AAC1E","sourcesContent":[".resultCard-container {\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.resultCard {\n  padding: 1.6em;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n}\n\n.resultCard-content {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.resultCard-content h2 {\n  font-weight: 700;\n}\n\n.resultCard-content span {\n  font-weight: 300;\n}\n\n.resultCard-content > span {\n  font-size: 14px;\n}\n\n.resultCard-section {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.zenvi-arrow-icon-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.zenvi-arrow-icon {\n  width: 8rem;\n  height: 5rem;\n  max-height: 20vh;\n  object-fit: contain;\n}\n\n.provider-text {\n  margin-top: 8px; /* Adjust the spacing as needed */\n  font-size: 12px;\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .zenvi-arrow-icon {\n    height: 3rem;\n  }\n}\n\n.headerContainer {\n  text-align: center;\n  margin-bottom: 0px; /* Adjust this value to add space below the header */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
