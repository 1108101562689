import React, { useEffect } from "react";
import HelloSign from 'hellosign-embedded';

const StepDropboxSign = (props) => {
  useEffect(() => {
    const embeddedSigningUrl = props.embeddedSigningUrl;

    if (embeddedSigningUrl) {
      const url = new URL(embeddedSigningUrl);
      const clientId = url.searchParams.get('client_id');

      if (clientId) {
        HelloSign.init(clientId);

        HelloSign.open({
          url: embeddedSigningUrl,
          container: document.getElementById('embedded-signing'),
          height: 500,
          width: 700,
          messageListener: function(eventData) {
            console.log(eventData);
            if (eventData.event === 'signature_request_signed') {
              props.onSignComplete();
            }
          }
        });
      } else {
        console.error("Client ID not found in the URL");
      }
    }
  }, [props.embeddedSigningUrl, props]);

  return (
    <div style={StepDropboxSignContainer}>
      <h1>Firma la autorizacion para que podamos negociar en tu nombre</h1>
      <div id="embedded-signing" style={embeddedSigningContainer}></div>
    </div>
  );
};

const StepDropboxSignContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
};

const embeddedSigningContainer = {
  width: '700px',
  height: '500px',
};

export default StepDropboxSign;