// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plug-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & > h2 {
    margin-top: 20px;
  }

  & > * {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/JiroForm/StepSix/StepSix.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".plug-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n\n  & > h2 {\n    margin-top: 20px;\n  }\n\n  & > * {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
